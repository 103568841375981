import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SliderBanner = () => {
  // Configurações do slider
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000
  };

  // Dados das mensagens com links
  const slidesData = [
    {
      mensagem: "RESGATAR BONUS DE CADASTRO",
      link: "https://go.aff.br4-partners.com/l59wgsqt",
      image: "br4bet-logo"
    },
    {
      mensagem: "RESGATAR BONUS DE CADASTRO",
      link: "https://go.aff.br4-partners.com/l59wgsqt",
      image: "br4bet-logo2"
    }
  ];

  return (
    <div className="slider-banners">
      <Slider {...settings}>
        {slidesData.map((slide, index) => (
          <div
            key={index}
            className={slide.image}
            onClick={() => window.open(slide.link, "_blank")}
          >
            <div className="overlay" style={{ padding: 12 }}>
              <a
                href={slide.link}
                className="btn btn-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                {slide.mensagem}
              </a>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderBanner;
